<template>
    <nav class="menu" ref="menu">
            <ul>
               <li  @click="closeMenu" v-for="item in this.links" ref="items" :key="item.name"> <router-link :to="item.link">{{item.name}}</router-link></li>
            </ul>
             <div class="menu__socials">
                <button><icon-base class="social facebook" iconColor="rgb(160, 160, 160)" width="30" viewBox="0 0 300 300"><icon-facebook /></icon-base></button>
                <button><icon-base class="social youtube" iconColor="rgb(160, 160, 160)" width="30" viewBox="0 0 300 300"><icon-youtube /></icon-base></button>
                <button><icon-base class="social soundcloud" iconColor="rgb(160, 160, 160)" width="30" viewBox="0 0 56 56"><icon-soundcloud /></icon-base></button>
            </div>
    </nav>
</template>
<script>
import IconBase from '@/assets/icons/IconBase'
import IconFacebook from '@/assets/icons/IconFacebook'
import IconYoutube from '@/assets/icons/IconYoutube'
import IconSoundcloud from '@/assets/icons/IconSoundcloud'

export default {
    name: 'NavbarMobile',
    components: {
        IconBase,
        IconFacebook,
        IconYoutube,
        IconSoundcloud,
    },
    data() {
        return {
            links: [
                {link: '/',name: 'Home'},
                {link: '/faq',name: 'About'},
                {link: '/favourites',name: 'Favourites'},
                {link: '/contact',name: 'Contact'},
            ]
        }
    },
    methods: {
        closeMenu() {
            this.$emit('toggleMenu', false);
        }
    }
}
</script>
<style lang="scss" scoped>
.menu {
    position: fixed;
    top: 58px;
    height: 100vh;
    width: 101vw;
    transition: transform .6s;
    z-index: 9;
    transform: translateX(100vw);
    display: flex;
    flex-direction: column;
    padding-top: 60px;



    & ul {
        width: 86%;
        margin: 0 auto;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 4rem;
    
        & li {
            @include fontPoppins;
            list-style: none;
            color: $middleText;
            margin-top: 10px;
            transition: transform .6s, opacity .6s;
            transform: translateY(10px);
            text-align: center;
            font-size: 30px;
            letter-spacing: 3px;
            text-align: center;

            a {
                display: block;
            }
        }

    }

    .is_visible {
            & li {
                transform: translateY(0px);
                opacity: 1;
            }
    }

    &__socials {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 50%;
        margin: 30px auto;
    }


}
</style>