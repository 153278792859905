import Instance from "./Service";


export default {
  async getAll() {
    const resp = await Instance.get('/api/licensions')
    const data = resp.data;
    return data;
  }
};
