<template>
  <footer class="footer">
      <p>Empe Beats &copy; {{new Date().getFullYear()}} All Rights Reserved</p>
      <p>Designed and developed by <a href="/">Maciej Pietrołaj</a></p>
  </footer>
</template>

<script>


export default {
  name: 'Footer',

}
</script>

<style lang="scss" scoped>
.footer {
  @include fontPoppins;
  display: flex;
  column-gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(46, 46, 46);;
  // flex-direction: column;
  margin: 0 auto;
  width: 86%;
  font-size: 12px;
  height: 5rem;
  padding: 0.75rem 0px;


  a {
    color: $main;
    transition: color .22s;
    opacity: 0.7;

    &:hover {
      color: $white;
    }
  }
}

</style>