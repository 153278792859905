import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import AudioVisual from 'vue-audio-visual'
import VueBreadcrumbs from 'vue-2-breadcrumbs';

Vue.use(AudioVisual)
Vue.use(VueBreadcrumbs, {
    template:
      '        <nav v-if="$breadcrumbs.length" aria-label="breadcrumb">\n' +
      '            <ul class="breadcrumb">\n' +
      '                <li v-for="(crumb, key) in $breadcrumbs" v-if="crumb.meta.breadcrumb" :key="key" class="breadcrumb-item active" aria-current="page">\n' +
      '                    <div class="separator" v-if="key != 0"></div>' +
      '                    <router-link :to="{ path: getPath(crumb) }">{{ getBreadcrumb(crumb.meta.breadcrumb) }}</router-link>' +
      '                </li>\n' +
      '            </ul>\n' +
      '        </nav>'
});
Vue.config.productionTip = false;



new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
