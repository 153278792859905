// import store from "..";

// initial state
const state = {
    beat: {},
    isPlaying: false,
    clickedID: 0,
    volume: 0.8,
  };
  
  // getters
  const getters = {
    getCurrBeat(store) {
      return store.beat
    },
    getIsPlaying(store) {
      return store.isPlaying
    },
    getClickedID(store) {
      return store.clickedID
    },
    getVolume(store) {
      return store.volume;
    }
  };
  
  // actions
  const actions = {
    setCurrBeat({commit}, payload) {
      commit("setCurrBeat", payload);
    },
    setIsPlaying({commit}, payload) {
      commit("setIsPlaying", payload);
    },
    setVolume({commit}, payload) {
      commit("setVolume", payload);
    },
  };
  
  // mutations
  const mutations = {
    setCurrBeat(state, payload) {
        state.beat = payload;
      },
    setIsPlaying(state, payload) {
      state.isPlaying = payload.isPlaying
      state.clickedID = payload.clickedID
    },
    setVolume(state, payload) {
      state.volume = payload;
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  