<template>
  <div id="app">

    <Navbar @toggleMenu="toggleMenu" :isMenuOpen="isMenuOpen" />
    <transition name="router"  v-on:leave="leave" v-on:after-enter="enter" v-on:before-enter="beforeEnter" >
      <router-view ref="router" />
    </transition>
    <NavbarMobile :isMenuOpen="isMenuOpen" @toggleMenu="toggleMenu" ref="sidebar"/>
    <Player ref="player"/>
    <Footer />

  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import NavbarMobile from '@/components/NavbarMobile'
import Footer from '@/components/Footer'
import Player from '@/components/Player'
import gsap from 'gsap'
import { mapGetters } from 'vuex'


export default {
  name: 'App',
  components: {
    Navbar,
    NavbarMobile,
    Player,
    Footer,
  },
  data() {
    return{
      isMenuOpen: false,
    }
  },
  mounted() {
        this.$store.dispatch("beats/getAll");
    this.$store.dispatch("favourites/setFavourites");
  },
  methods: {
    toggleMenu() {
      // if(fromSidebar) this.$refs.nav.changeButton();
      this.isMenuOpen = !this.isMenuOpen;

      // if(!this.$refs.sidebar || !this.$refs.router) return;
      if(window.innerWidth <= 767) {
        if(this.isMenuOpen) {
          this.$refs.router.$el.style.transition = 'transform 0.6s'
          this.$refs.router.$el.style.transform = 'translateX(-100vw)'
          this.$refs.sidebar.$el.style.transform = 'translateX(-1vw)'
          document.documentElement.style.overflow = 'hidden'
        } else {
          this.$refs.router.$el.style.transform = 'translateX(0vw)'
          this.$refs.sidebar.$el.style.transform = 'translateX(99vw)'
          document.documentElement.style.overflow = 'scroll'
        }
      }


    },
    leave(el, done){
      gsap.to(el, {opacity: 0, onComplete: () => done()}).duration(0.25)
    },
    enter(el){
      gsap.to(el, {opacity: 1, delay: 0.25}).duration(0.25)
    },
    beforeEnter(el) {
      gsap.set(el, {opacity: 0})
    }
  },
  computed: {
    ...mapGetters('beats',['getNewest']),
  },
  watch: {
    getNewest(val) {
      this.$store.dispatch("player/setCurrBeat", val);
        this.$store.dispatch("player/setIsPlaying", {
          isPlaying: false,
          clickedID: val.id
        });
    }
  }
}
</script>
<style lang="scss">

#app {
  &::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 200vw;
    height: 50vh;
    background: linear-gradient(0deg, $lightGrey 10%, $black 70%);
    z-index: -1;
  }
}
</style>

