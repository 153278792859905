<template>
       <nav :class="{'scrolled': scrolled, 'is-subpage': $route.path != '/'}">
            <router-link to="/"><icon-base class="logo" iconColor="rgb(160, 160, 160)" stroke="rgb(160, 160, 160)" width="50" height="54" viewBox="0 0 141 130"><icon-logo /></icon-base></router-link>

            <div class="menu">
                <div :class="{'menu__desktop': true, 'menu__desktop--active': isMenuOpen}">
                    <router-link to="/faq">
                        <icon-question/>
                    </router-link>
                    <router-link to="/favourites">
                            <icon-base width="12" height="11" iconColor="rgb(160, 160, 160)" viewBox="-150 -160 240 200" ><icon-hearth/></icon-base>
                            <span class="menu__favs" v-show="getFavourites.length > 0">{{getFavourites.length}}</span>
                    </router-link>
                    <router-link to="/contact">
                            <icon-mail />
                    </router-link>
                </div>

                <div class="hamburger" @click="toggleMenu" :class="{'hamburger--active': isMenuOpen}">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
       </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import IconBase from '@/assets/icons/IconBase'
import IconLogo from '@/assets/icons/IconLogo'
import IconHearth from '@/assets/icons/IconHearth'
import IconQuestion from '@/assets/icons/IconQuestion'
import IconMail from '@/assets/icons/IconMail'



export default {
    name: 'Navbar',
    data() {
        return {
            scrolled: false,
        }
    },
    props: {
        isMenuOpen: Boolean
    },
    components: {
        IconBase,
        IconLogo,
IconMail,
        IconQuestion,
        IconHearth
    },
    computed: {
        ...mapGetters('favourites',['getFavourites']),
        },
    mounted() {
        this.listenScroll();
    },
    methods: {
        listenScroll() {
            window.addEventListener('scroll', () => {
                this.scrolled = window.scrollY > 0;
            })
        },
        toggleMenu() {
            this.$emit('toggleMenu');
        }
 
    }
}
</script>

<style lang="scss" scoped>
nav {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // height: 8rem;
    z-index: 10;
    padding: .6rem 4% 1rem 2%;
    transition: background-color .3s;

    @include mq($from: mobile) {
        padding: 1.5rem 7%;
    }

    & > a {
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s;
    }

    svg {
        transition: transform .22s;
    }

    &.is-subpage {
        svg {
            transform: scale(0.8);
        }
        & > a {
            opacity: 1;
            visibility: visible;
            transition: opacity .3s ease, visibility 0s;
        }
    }

   

    .hamburger {
        width: 48px;
        height: 48px;
        margin-right: -.4rem;
        transform: translateX(6px);
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: .8rem;
        -webkit-tap-highlight-color: transparent;

            @include mq($from: mobile) {
                margin-right: -2rem;
            }

        &:hover {
            & span:first-of-type {
                transform: translateX(26%) scaleX(0.5);
            }

            & span:nth-of-type(2) {
                transform: translateX(18%) scaleX(0.64);
            }

            & span:nth-of-type(3) {
                transform: translateX(-30%) scaleX(1.6);
            }
        }

        & span {
            display: block;
            width: 50%;
            height: 1px;
            background-color: $middleText;
            margin-top: 6px;
            transition: transform .2s, width .2s, opacity .2s;
        }

        & span:first-of-type {
            width: 40%;
            margin-left: 10%;
        }

        & span:nth-of-type(3) {
            width: 28%;
            margin-left: 20%;
        }

        &--active {
            & span:first-of-type { 
                width: 56%;
                transform: translate(1px, 9px) rotate(-45deg)!important;
            }

            & span:nth-of-type(2) {
                opacity: 0;
            }

            & span:nth-of-type(3) {
                width: 56%;
                transform: translate(-1px, -5px) rotate(45deg)!important;
            }
        }
    }

    .menu {
        display: flex;
        position: relative;
        gap:1rem;

        &__desktop {
            display: none;
            align-items: center;
            gap: 4px;
            position: absolute;
            left: -0.5rem;
            top: 50%;
            transform: translate(-100%, -50%);

            @include mq($from: tablet) {
                display: flex;
            }


            a {
                display: block;
                width: 40px;
                height: 40px;
                opacity: 0;
                visibility: hidden;
                background: rgba(0, 0, 0, 0.5);
                border-radius: 50%;

                &:hover {
                    filter: brightness(120%);
                }

                &:last-of-type {
                    margin-left: 4px;
                }

                @for $i from 1 through 5 {
                    &:nth-of-type(#{$i}) {
                        transition: opacity #{.16 + $i / 8}s, visibility 2s;
                    }
                }
            }

            &--active {
                a {
                    opacity: 1;
                    visibility: visible;

                    @for $i from 1 through 5 { 
                    &:nth-of-type(#{$i}) {
                        transition: opacity #{1.5 - $i / 8}s, visibility 0s;
                    }
                }
                }
            }
        }

        &__favs {
            @include fontMontserrat;
            position: absolute;
            font-size: 1rem;
            color: white;
            text-align: center;
            border-radius: 50%;
            top: 52%;
            left: 49%;
            transform: translate(-50%, -50%);
            transition: all .22s;
        }

    }
}
</style>