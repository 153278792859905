<template>
  <svg xmlns="http://www.w3.org/2000/svg" 
    :width="width" 
    :height="height" 
    :viewBox="viewBox"
    :aria-labelledby="iconName" 
    role="presentation"
  >
    <g :fill="iconColor" :stroke="stroke">
      <slot />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    stroke: {
      type: String,
      default: 'currentColor'
    },
    viewBox: {
      type: [Number, String],
      default: "0 0 18 18"
    },
    isPlaying: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  overflow: visible;
}
.play{
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke-dasharray: 115;
    transition: stroke-dashoffset .6s ease;
}
.play__active {
    stroke-dashoffset: 115;
}
</style>