import Vue from "vue";
import Vuex from "vuex";
import beats from './modules/beats'
import favourites from './modules/favourites'
import player from './modules/player'
import youtube from './modules/youtube'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

const store = new Vuex.Store({
  modules: {
    beats,
    favourites,
    player,
    youtube
  },
  strict: debug,
});

export default store;
