import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      breadcrumb: 'Home'
    }
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import('../views/Info.vue')
  },
  {
    path: '/beat/:slug',
    name: 'BeatPage',
    props: true,
    component: () => import('../views/BeatPage.vue'),
    meta: {
      breadcrumb() {
        return {
          label: capitalizeFirstLetter(this.$route.params.slug.replace('_', ' ')),
          parent: 'Home'
        };
      }
    }
  },
  {
    path: '/favourites',
    name: 'Favourites',
    component: () => import('../views/Favourites.vue'),
    meta: {
      breadcrumb() {
        const { name } = this.$route;

        return {
          label: name,
          parent: 'Home'
        };
      }
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/ContactPage.vue'),
    meta: {
      breadcrumb() {
        const { name } = this.$route;

        return {
          label: name,
          parent: 'Home'
        };
      }
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue'),
    meta: {
      breadcrumb() {
        const { name } = this.$route;

        return {
          label: name,
          parent: 'Home'
        };
      }
    }
  },
  // {
  //   path: '/download/:beatName',
  //   name: 'Download',
  //   component: () => import('../views/Download.vue'),
  //   props: true
  // }
]

const router = new VueRouter({
  mode: "history",
  routes
})

export default router
