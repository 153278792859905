import Instance from "./Service";


export default {
  async sendEmail(data) {
    console.log('send');
    const resp = await Instance.post('/api/email', data)
    return resp.data;
  }
};
