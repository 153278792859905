<template>
  <section class="player" :class="{is_open: isOpen}">

        <div class="player__progress">
            <span class="player__progress__bar" ref="bar"></span>
            <span class="player__progress__bar__overlay"></span>
            <input 
                @input="setProgress($event)"
                type="range"
                ref="progress"
                name="progress" 
                :min="0" :max="1"
                :step="0.001"
                :value="0"
                />
        </div>

        <main>
            <div class="player__sign" @click="isOpen = !isOpen">
                <icon-base v-show="!isOpen" width="24" height="24" stroke="rgb(255, 255, 255)" iconColor="rgb(255, 255, 255)" viewBox="0 0 512 512" >
                    <icon-headphones />
                </icon-base>

            </div>


            <span class="player__image">
                <img :src="beat.image" v-if="beat.image" alt="thumbnail of beat">
                <img src="@/assets/img/placeholder.png" v-else alt="disck image - placeholder">
                <div class="player__details">
                    <h3 @click="changeRoute">{{beat.name}}</h3>
                    <ul>
                        <li>{{beat.time}}</li>
                        <li>{{beat.tempo}} BPM</li>
                        <li>{{beat.tune}}</li>
                    </ul>
                </div>
            </span>


            <div class="player__controls">
         
                    <span class="player__controls__prev" @click="playPrevNext('prev')"></span>

                    <button @click="togglePlay" class="player__controls__play">
                        <icon-base width="33" height="38" stroke="rgb(255, 255, 255)" viewBox="0 0 33 38" >
                            <icon-play :isPlaying="isPlaying"/>
                        </icon-base>
                    </button>
                    <button  @click="togglePlay" class="player__controls__stop" :class="{is_active: isPlaying}">
                    </button>

                    <span class="player__controls__next" @click="playPrevNext('next')"></span>
       

            </div>

            <div class="player__volume">
                <button @click="toggleMute" class="player__volume__speaker">
                        <icon-base width="24" height="24" iconColor="rgb(255, 255, 255)" viewBox="0 0 46 46"><icon-speaker :isMute="isMute"/></icon-base>
                        <span class="player__volume__speaker__crossOut" ref="speakerCross"></span>
                </button>

                <div class="player__volume__input">
                    <span class="player__volume__input__bar" ref="barVol"></span>
                    <span class="player__volume__input__bar__overlay"></span>
                    <input 
                        @input="setVolume($event)"
                        type="range"
                        ref="volume"
                        name="volume" 
                        :min="0" :max="1"
                        :step="0.001"
                        :value="0"
                    />
                </div>
            </div>

            <div class="player__buttons">
                    <button @click="changeRoute"><icon-base width="6" height="5" iconColor="rgb(255, 255, 255)" viewBox="0 0 128 128" ><icon-cart/></icon-base></button>
                    <button @click="addToFavourite"><icon-base width="6" height="6" iconColor="rgb(255, 255, 255)" viewBox="0 0 150 150" ><icon-hearth :isFavourite="isFavourite"/></icon-base></button>
            </div>
        </main>
  </section>
</template>

<script>
import IconBase from '@/assets/icons/IconBase.vue';
import IconPlay from '@/assets/icons/IconPlay'
import IconCart from '@/assets/icons/IconCart.vue';
import IconHeadphones from '@/assets/icons/IconHeadphones.vue';
import IconHearth from '@/assets/icons/IconHearth.vue';
import IconSpeaker from '@/assets/icons/IconSpeaker.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    name: 'Player',
    components: {
        IconBase,
        IconPlay,
        IconCart,
        IconHearth,
        IconSpeaker,
        IconHeadphones
    },
    data() {
        return {
            isPlaying: false,
            audio: null,
            volume: this.getVolume,
            isMute: false,
            timeScale: 0,
            isFavourite: false,
            initRun: false,
            isOpen: false,
        }
    },
    computed: {
        ...mapState({allBeats: (state) => state.beats.all}),
        ...mapGetters('player',['getCurrBeat']),
        ...mapGetters('player',['getIsPlaying']),
        ...mapGetters('player',['getClickedID']),
        ...mapGetters('player',['getVolume']),
        ...mapGetters('favourites',['getFavourites']),
        beat(){
            return this.getCurrBeat || false
        },
    },
    methods: {
        changeRoute() {
            this.$router.push({path: `/beat/${this.getCurrBeat.slug}`, params: {name: this.getCurrBeat.slug}})
        },
        initPlayer() {
            this.audio = new Audio(this.beat.audio);
            this.audio.volume = this.getVolume
            this.audio.addEventListener("timeupdate", () => {
                this.timeScale = this.audio.currentTime / this.audio.duration
                if(this.audio.currentTime >= this.audio.duration) this.playPrevNext('next')
            });
        },
        addToFavourite(){
            this.isFavourite = !this.isFavourite
            this.$store.dispatch( `favourites/${this.isFavourite ? 'add' : 'remove'}Favourite`, this.getClickedID);
        
        },
        togglePlay() {
            this.isPlaying = !this.isPlaying;
            this.updateStorePlayingState();
        },
        setProgress(e) {
            this.audio.currentTime = +e.target.value * this.audio.duration;         
        },
        setVolume(e) {
            this.volume = e.target.value
            this.$refs.barVol.style.transform = `scaleX(${this.volume})`
            this.isMute = false
        },
        toggleMute() {
            this.isMute = !this.isMute

            if(this.isMute) {
                this.volume = 0
            } else {
                this.volume = this.getVolume
            }
            this.$refs.barVol.style.transform = `scaleX(${this.isMute ? 0 : this.volume})`
        },
        playPrevNext(e) {
            const direction = e === 'next' ? 1 : -1

            let nextIndex = this.allBeats.indexOf(this.getCurrBeat) + direction

            if(nextIndex >= this.allBeats.length) {
                nextIndex = 0
            } else if(nextIndex === -1){
                nextIndex = this.allBeats.length - 1
            }

            this.$store.dispatch("player/setCurrBeat", this.allBeats[nextIndex]);

            const clickedID = this.allBeats[nextIndex].id
            this.updateStorePlayingState(clickedID)
        },
        checkIfFavourite() {
            if(this.getFavourites.includes(this.getClickedID)) return this.isFavourite = true
            this.isFavourite = false
        },
        updateStorePlayingState(clickedID = this.getClickedID) {
            this.$store.dispatch("player/setIsPlaying", {
                isPlaying: this.isPlaying,
                clickedID: clickedID
            });
        }
    },
    watch: {
        beat(){
            this.audio?.pause();
            this.initPlayer();


            this.isPlaying = this.getIsPlaying;
            const action = this.getIsPlaying ? 'play' : 'pause'
            this.audio[action]();
        },
        timeScale() {
            this.$refs.bar.style.transform = `scaleX(${this.timeScale})`
        },
        getIsPlaying() {
            if(this.getIsPlaying) this.isOpen = true;

            this.isPlaying = this.getIsPlaying;
            const action = this.getIsPlaying ? 'play' : 'pause'
            this.audio[action]();
        },

        volume() {
            this.audio.volume = this.volume
            this.$store.dispatch("player/setVolume", this.volume);
        },
        getFavourites() {
             this.checkIfFavourite()
        },
        isMute() {
                  this.$refs.speakerCross.style.opacity = this.isMute ? 1 : 0
        }
    },
}
</script>

<style scoped lang="scss">
.player {
    position: fixed;
    bottom: 0;
    background-color: rgba(17, 17, 17, 0.9);
    height: 8.6rem;
    width: 100vw;
    @include fontPoppins;
    transform: translateY(100%);
    transition: transform .25s;
    z-index: 9;

    @include mq($to: mobile) {
        height: 6.6rem;
    }

    &.is_open {
        transform: translateY(0);

        .player__sign {
            transform: translate(10px, calc(-100% + 10px)) scale(0.6);
            opacity: .6;

            &:hover {
                opacity: 1;
                transform: translate(10px, calc(-100% + 10px)) scale(0.72);
            }

            &::before, &::after {
                content: '';
                position: absolute;
                width: 2rem;
                height: 1px;
                background-color: $white;
                transform: rotate(-45deg);
            }

            &::after {
                transform: rotate(45deg);
            }
        }
    }

    &.is_blocked {
        .player__sign {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0;
        }
    }
    

    & main {
        width: 86%;
        max-width: 1300px;
        height: 100%;
        position: relative;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mq($to: mobile) {
            width: 92%;
        }
    }

    &__sign {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 0;
        top: -10px;
        transform: translate(10px, -100%);
        width: 4.4rem;
        height: 4.4rem;
        background-color: rgba(17, 17, 17, 0.9);
        border-radius: 50%;
        cursor: pointer;
        transition: transform .15s, opacity .15s;
        

    }

    &__progress{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        & input {
            width: 100vw;
            height: 10px;
            transform: translateY(8px);
        }
        
        &__bar {
            width: 100%;
            height: 3px;
            background-color: $middleText; //TODO change colors
            position: absolute;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform .2s;
            left:0%;
            z-index: 0;

            &__overlay {
                width: 100%;
                height: 3px;
                background-color: transparent; //TODO change colors
                position: absolute;
                transform-origin: left;
                left: 0;
                z-index: -1;
            }
        }
    }

    &__image {
        display: flex;
        align-items: center;
        
        & img{
            height: 56px;
            width: 56px;
            border-radius: 6px;

            @include mq($to: desktop) {
                height: 4.6rem;
                width: 4.6rem;
            }

            @include mq($to: tablet) {
                display: none;
            } 
        }

        .player__details {
            height: 100%;
            color: $lightText;
            margin: 0 auto 0 30px;
            display: flex;
            align-items: center;

            @include mq($to: wide) {
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
            }

            @include mq($to: desktop) {
                margin-left: 1.6rem;
            }

            @include mq($to: tablet) {
                margin-left: 0;
            }

            & h3 {
                font-size: 24px;
                font-weight: 400;
                letter-spacing: 0.9px;
                cursor: pointer;


                @include mq($to: wide) {
                    font-size: 2rem;
                }

                @include mq($to: mobile) {
                    font-size: 1.6rem;
                }
                
            }

            & ul{
                font-size: 10px;
                display: flex;
                margin-left: 2rem;

                @include mq($to: wide) {
                    margin-left: 0;
                }

                @include mq($to: desktop) {
                    display: none;
                }
                            
                & li {
                    list-style: none;
                    font-size: 12px;
                    margin-right: 12px;
                    margin-top: 5px;
                    white-space: nowrap;
                }
            }
        }
    }

    &__controls {
        position: absolute;
        display: flex;
        flex-direction: row;      
        align-items: center;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* position: relative; */

        @include playStopBtn;


        &__stop {
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: 60px;

            @include mq($to: mobile) {
            transform: translate(-50%, -50%) scale(0.7);
            }

            &::before, &::after {
                transform: translate(-50%, -50%);
            }
        } 

        &__play {
            position: relative;
            margin: 0;
            transform: none;
            margin: 0 5rem;

            @include mq($to: mobile) {
                transform: scale(0.7);
                margin: 0 1.2rem;
            }
        }


        &__prev, &__next {
            display: block;
            width: 30px;
            height: 30px;
            background-image: url('../assets/img/next.svg');
            background-repeat: no-repeat;
            background-size: contain;
            filter: brightness(200%);
            cursor: pointer;

            @include mq($to: mobile) {
                transform: scale(0.8);
            }
        }

        &__prev {
            transform: rotate(-180deg);

            @include mq($to: mobile) {
                transform: rotate(-180deg) scale(0.8);
            }
        }
    }

    &__volume {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-left: auto;
        
        @include mq($to: desktop) {
            display: none;
        }

        input[type=range] {
            height: 100%;
            transform: translateY(12px);
            /* height: 30px; */
        }

        &__input {
            position: relative;
            margin-left: 1rem;
            transform: translateY(-2px);

            &__bar {
            width: 100%;
            height: 3px;
            background-color: $lightText; //TODO change colors
            position: absolute;
            display: block;
            transform: scaleX(0.8);
            transform-origin: left;
            transition: transform .2s;
            left: 0;
            top: 50%;
            z-index: 0;

            &__overlay {
                width: 100%;
                height: 3px;
                background-color: $box; //TODO change colors
                position: absolute;
                transform-origin: left;
                left: 0;
                top: 50%;
                z-index: -1;
            }
        }
    }

    &__speaker {
        position: relative;
        cursor: pointer;

        &__crossOut {
            position: absolute;
            left: -6px;
            top: 9px;
            transform: rotate(-45deg);
            opacity: 0;
            width: 30px;
            height: 2px;
            background-color: $almound;
            transition: opacity .4s;
        }
    }
}


    &__buttons {
        height: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        transform: translateX(7px);
        margin-left: 2rem;

        & button {
            background-color: transparent;
            border: none;
            width: 32px;
            height: 32px;
            outline: none;
            margin-left: 10px;
            transform: translateY(2px);
            cursor: pointer;

            @include mq($to: mobile) {
                transform: translate(0px, 2px) scale(0.8);
            }
        }

        & button:first-of-type {
            transform: translateX(-10px);

            @include mq($to: mobile) {
                transform: translate(8px, 1px) scale(0.8);
            }

        }

        & svg {
            transform: translate(-10px, -12px);
            width: 6px;
            height: 6px;
        }
    }

}


input[type=range] {
  -webkit-appearance: none;
  -moz-appearance:none;
  -webkit-tap-highlight-color: transparent;
  background: transparent;
  height: 16px;
  cursor: pointer;
  position: relative;
  pointer-events: none;
  z-index: 1;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    pointer-events: auto;
    width: 100%;
    height: 30px;
    cursor: pointer;
    background: transparent;
    margin-top: -20px;
    border-radius: 1.3px;
}

input[type=range]::-webkit-slider-thumb {
  height: 13px;
  width: 10px;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  left: 0;
}


input[type=range]::-webkit-slider-runnable-track {
    pointer-events: auto;
    width: 100%;
    height: 30px;
    cursor: pointer;
    background: transparent;
    margin-top: -20px;
    border-radius: 1.3px;
}

input[type=range]::-webkit-slider-thumb {
  height: 13px;
  width: 10px;
  border-radius: 50px;
  background: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  left: 0;
}



input[type=range]::-moz-range-track {
    pointer-events: auto;
    width: 100%;
    height: 30px;
    cursor: pointer;
    background: transparent;
    margin-top: -20px;
    border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb  {
    height: 13px;
    width: 10px;
    border-radius: 50px;
    background: transparent;
    cursor: pointer;
    -webkit-appearance: none;
    left: 0;
}

</style>