import { YoutubeService } from "@/api";

// initial state
const state = {
    subs: 0,
    views: 0,
    videos: 0,

};

// getters
const getters = {
    getData(store) {
        return {
            subs: store.subs,
            views: store.views,
            videos: store.videos
        }
    }
};

// actions
const actions = {
  async getData({ commit }) {
    const data = await YoutubeService.getData();

    commit("setData", data);
  },
};

// mutations
const mutations = {
    setData(state, data) {
        state.subs = data.items[0].statistics.subscriberCount;
        state.views = data.items[0].statistics.viewCount;
        state.videos = data.items[0].statistics.videoCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
