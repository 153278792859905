// initial state
const state = {
  favourites: [],
};

// getters
const getters = {
    getFavourites(store) {
        return store.favourites
    }
};

// actions
const actions = {
  addFavourite({commit}, payload) {
    localStorage.setItem('empe_beat_' + payload, payload);
    commit("addFavourite", payload);
  },
  removeFavourite({commit}, payload){
    localStorage.removeItem('empe_beat_' + payload);
    commit("removeFavourite", payload);
  },
  setFavourites({ commit }) {
    for(const key of Object.keys(localStorage)) {
      if(key.includes('empe_beat')) {
        const arr = key.split('_');
        const id = key.split('_')[arr.length - 1];
        commit("setFavourites", +id);
      }
    }
  }
};

// mutations
const mutations = {
    addFavourite(state, payload) {
      const id = payload;
      state.favourites.push(id)
    },
    removeFavourite(state, id) {
        const index = state.favourites.indexOf(id);

        if (index > -1) {
        state.favourites.splice(index, 1);
        }
    },
    setFavourites(state, id) {
      state.favourites.push(id)
    }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
