import axios from 'axios'
const apiKey = 'AIzaSyDaNmxIBhYSl8ybJNE7OxdDBovKzIR-vds'
const channelID = 'UCVZelXfT42MbxpmKi-a7g-g'
const resource = `https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${channelID}&key=${apiKey}`

export default {
  async getData() {
    
    const resp = await axios.get(resource)
    const data = resp.data;
    return data;
  }
};
