import { BeatsService } from "@/api";

// initial state
const state = {
  all: false,
};

// getters
const getters = {
  getNewest(store) {
      return store.all[0]
  }
};

// actions
const actions = {
  async getAll({ commit }) {
    const data = await BeatsService.getAll();

    commit("setAll", data);
  },
};

// mutations
const mutations = {
  setAll(state, data) {
    state.all = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
