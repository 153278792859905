<template>
    <g>
    <path
      class="play"
      :class="{play__active: isPlaying}"
        d="M-51.85,478.64v-17a1,1,0,0,1,1.51-.86l29,17a1,1,0,0,1,0,1.73l-29,17a1,1,0,0,1-1.51-.86v-17" transform="translate(52.85 -459.64)"></path>
    </g>
         
</template>

<script>
export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>
.play{
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke-dasharray: 115;
    transition: stroke-dashoffset .6s ease;
}
.play__active {
    stroke-dashoffset: 115;
}
</style>